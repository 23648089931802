import React from "react"
import Container from "@material-ui/core/Container"
import { Link } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Ugh." />
    <Container
      style={{ paddingTop: 60, paddingBottom: 55, textAlign: "center" }}
    >
      <h1 style={{ fontSize: 70 }}>404</h1>
      <h3>Page Not Found :-(</h3>

      <Link
        className="btn-hack"
        to="/"
        style={{
          marginTop: 30,
          fontSize: 20,
          maxWidth: 300,
          display: "inline-block",
        }}
      >
        Freedsound Home &rarr;
      </Link>
    </Container>
  </Layout>
)

export default NotFoundPage
